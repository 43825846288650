.modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #1976d2;
    color: white;
    padding: 10px;
  }
  .modal-title .title {
    flex-grow: 1;
  }
  .modal-title .close-icon {
    cursor: pointer;
  }