.disabled-form {
    pointer-events: none;
    opacity: 0.6;
  }
  
  .hideScrollBar::-webkit-scrollbar{
      display: none;
  }
  .showScrollBar::-webkit-scrollbar{
    display: block;
  }