.textfield-wrapper .MuiFormControl-root .MuiFormLabel-root {
  top: -7px;
}

.textfield-wrapper .MuiFormControl-root .MuiInputBase-root {
  padding: 0px;
  margin-top: 0px;
}
.textfield-wrapper .MuiFormControl-root .MuiInputBase-root input {
  padding: 8px 14px;
}
.textfield-wrapper
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-root
  button {
  margin: 0;
}
/* style for mui dropzone */
#user-dropzone .MuiDropzoneArea-root {
  min-height: 80px !important;
  border: 10px solid #1e5b8726;
  border-radius: 10px;
  background: #fafafa;
  color: #999;
  padding: 0px;
}
#user-dropzone .MuiDropzoneArea-root .MuiDropzoneArea-textContainer p {
  font-size: 1.2rem;
}
#user-dropzone
  .MuiDropzoneArea-root
  .MuiDropzonePreviewList-root
  .MuiDropzonePreviewList-imageContainer {
  align-items: center;
  min-width: 90%;
  padding-left: 72px;
  padding-top: 0;
  margin: 35px auto 0px auto;
}

#user-dropzone
  .MuiDropzoneArea-root
  .MuiDropzonePreviewList-root
  .MuiDropzonePreviewList-imageContainer
  button {
  padding: 0;
  position: absolute;
  top: 65%;
  left: 56%;
  width: 2.2rem;
  height: 1rem;
  color: #ff0000;
  background: #2577b1b7;
  opacity: 1;
}
/* style for date picker in create user modal */
#user-date-picker-wrapper .MuiFormControl-root {
  min-width: 100%;
}

/* style for return qty field in lab refund billing module */
.refund-qty-input {
  width: 42%;
}

/* .warningRemaining{
  background-color: #f71a1a;
  width: "100% !important";
}
.successRemaining{
  background-color: #3575a8;
  width: "100% !important";
} */